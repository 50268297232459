.site-layout .site-layout-background {
  background: #fff;
}

.logo {
  width: 50px;
  margin: 10px auto;
}

.logo-image {
  width: 100%;
}
